import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { EncryptDecrypt } from '../../../../models/encryptDecrypt';
import { userLoginData } from '../../../../JSON/userLoginData';
import { UserLoginInfo } from '../../../../models/userLoginInfo';
import { UserInfo } from '../../../../models/userInfo';
import { ContractapproveService } from '../../../contractaggreement/services/contractapprove.service';
import { EventDetailsData } from '../../../../models/eventDetails';
import { SchedulerUserInfo } from '../../../../models/schedulerUserInfo';
import { PcacontractAgreementService } from '../../../patientcareadvocacy/Services/pcacontract-agreement.service';
import { environment } from '../../../../../environments/environment';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { PreviewService } from '../../../admin/services/preview.service';
import { universalContractReleaseDate } from '../../../../JSON/UniversalTandC';
import { Util } from '../../../../utility/util';
import { LulaAso } from '../../../../models/pcaTandC';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';
import { getContractComponentName } from '../../../../utility/loadContracts';

@Component({
  selector: 'app-userauthentication',
  templateUrl: './userauthentication.component.html',
  styleUrls: ['./userauthentication.component.css']
})
export class UserauthenticationComponent implements OnInit {

  popupShow: boolean = false;
  isCurrent: boolean = true;
  dialogMsg:string = "There are no stores assigned to you. Please contact administrator";
  showPreUniversalContract: boolean = false;
  oldPcaReleaseDate: any;
  PcaReleaseDate2022_1: any;
  routePageName : string ='';
  constructor(private router: Router, private authenticationService: AuthenticationService,
    private contractApproveService: ContractapproveService,
    private _contractService: PcacontractAgreementService, private _previewService: PreviewService) {
    if (environment.isSiteDeploying) {
      let data = this.getUserInfo(41);
      /*var user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;*/

      var user_profile = new UserInfo();
      user_profile.userName = data.userName;
      user_profile.userPk = data.pk;
      user_profile.userRole = "Admin";
      user_profile.isAdmin = true;
      user_profile.isPowerUser = false;
      user_profile.firstName = "";
      user_profile.lastName = "";
      SessionDetails.SetUserProfile(user_profile);
      this.router.navigate(['/landing']);
    }
    else {
      let current_Url = this.router.url;
      this.isAuthorise(current_Url);
    }
  }

  ngOnInit() {
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }

  // It is used to verifiy the authorization for the given encoded link.
  isAuthorise(encoded_value: string) { 
       debugger;
    if (encoded_value.includes("/contractAgreementForUser/")) {
   
      this.contractApproveService.setShowViewCancelBtn(false);
      let encoded_data = encoded_value.replace('/contractAgreementForUser/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          sessionStorage["clinic_agreement_pk"] = splited_Data[1];
          sessionStorage["storeId"] = splited_Data[0];
          sessionStorage["approveRejectEmail"] = splited_Data[2];
          this.router.navigate(['/contractAgreementForUser']);
        }
      });
    }
    else if (encoded_value.includes("/previous-client-interest/")) {
      this.contractApproveService.setShowViewCancelBtn(false);
      let encoded_data = encoded_value.replace('/previous-client-interest/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          sessionStorage["potentialInterestBusinessPk"] = splited_Data[0];
          sessionStorage["potentialInterestEmail"] = splited_Data[1];          
          this.router.navigate(['/previous-client-interest']);
        }
      });
    }
    else if (encoded_value.includes("/store-response/")) {
      
      this.contractApproveService.setShowViewCancelBtn(false);
      let encoded_data = encoded_value.replace('/store-response/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          sessionStorage["leadPk"] = splited_Data[0];                  
          sessionStorage["storeResForLead"] = splited_Data[1];                  
          sessionStorage["storeResForLeadStoreId"] = splited_Data[2];                  

          this.router.navigate(['/store-response']);
        }
      });
    }    
    else if (encoded_value.includes("/lulaContractAgreementForUser/")) {
      this.contractApproveService.setShowViewCancelBtn(false);
      let encoded_data = encoded_value.replace('/lulaContractAgreementForUser/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          sessionStorage["clinic_agreement_pk"] = splited_Data[1];
          sessionStorage["storeId"] = splited_Data[0];
          sessionStorage["approveRejectEmail"] = splited_Data[2];
          sessionStorage["clinic_type"] = splited_Data[3];
          let agreementPk = sessionStorage["clinic_agreement_pk"];
          if(agreementPk == null || agreementPk == undefined ){
            this.dialogMsg = ErrorMessages['nullClinicAgreementPk'];           
            this.popupShow = true;
            return;
          } 
          this._contractService.getPCAContractAgreement(agreementPk, null).subscribe((data:any) => {            
            if (data.isRHDApproved != null) {
              sessionStorage["testing_partner"] = 'TP';
              SessionDetails.fromUserPage(true);
              
              
              if(new Date(Util.getFormatedDate(data.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data.dateApproved) == null)
              {
              this.router.navigate(['/rhdContractAgreement-2023-1']);
              }
              else
              {
                this.router.navigate(['/rhdContractAgreement']);
              }
            }
            else if(new Date(Util.getFormatedDate(data.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data.dateApproved) == null)
            {
              this.router.navigate(['/lulaContractAgreementForUser-2023-1']);
            }
            else
            {
              this.router.navigate(['/lulaContractAgreementForUser']);
            }
          });
          //this.router.navigate(['/lulaContractAgreementForUser']);
        }
      });
    }
    else if (encoded_value.includes("/rhdContractAgreement/") || encoded_value.includes("/rhdContractAgreement-2023-1")) {
      this.contractApproveService.setShowViewCancelBtn(false);
      let encoded_data = encoded_value.replace('/rhdContractAgreement/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk;
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          var user_info = new UserLoginInfo();
          user_info.userName = splited_Data[0].replace('^', '.');
          user_info.password = splited_Data[1];
          SessionDetails.SetUserInfo(user_info);
          let agreementPk = sessionStorage["clinic_agreement_pk"] = splited_Data[3];
          sessionStorage["storeId"] = splited_Data[2];
          sessionStorage["clinic_type"] = splited_Data[4];
          sessionStorage["testing_partner"] = null;
          if(splited_Data[5]!=null && splited_Data[5]!=undefined)
          {
           sessionStorage["testing_partner"] = splited_Data[5];
          }
          this.authenticationService.getUserProfile().subscribe((data: any) => {
            if (data.dataList.length > 0) {
              var user_profile = new UserInfo();
              user_profile.email = data.dataList[0].email;
              sessionStorage["approveRejectEmail"] = data.dataList[0].email;
              user_profile.userName = data.dataList[0].userName;
              user_profile.userPk = data.dataList[0].userPk;
              user_profile.userRole = data.dataList[0].userRole;
              user_profile.isAdmin = data.dataList[0].isAdmin;
              user_profile.isPowerUser = data.dataList[0].isPowerUser;
              user_profile.firstName = data.dataList[0].firstName;
              user_profile.lastName = data.dataList[0].lastName;
              user_profile.locationType = data.dataList[0].locationType;
              user_profile.assignedLocations = data.dataList[0].assignedLocations;
              SessionDetails.SetUserProfile(user_profile);
              //if(this.isCurrent)
              SessionDetails.fromUserPage(true);
              if(agreementPk == null || agreementPk == undefined ){
                this.dialogMsg = ErrorMessages['nullClinicAgreementPk'];           
                this.popupShow = true;
                return;
              } 
             
              this._contractService.getPCAContractAgreement(agreementPk, null).subscribe((data:any) => {                             
                  if(new Date(Util.getFormatedDate(data.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data.dateApproved) == null)
                  {
                  this.router.navigate(['/rhdContractAgreement-2023-1']);
                  }
                  else
                  {
                    this.router.navigate(['/rhdContractAgreement']);
                  }
              });

              // this.router.navigate(['/communityoutreach/rhdContractAgreement']);
              //else
              //this.router.navigate(['/unauthorize']);
            }
          });
        }
      });
    }
    else if (encoded_value.includes("/contractAgreement/")) {
   
      this.contractApproveService.setShowViewCancelBtn(true);
      let encoded_data = encoded_value.replace('/contractAgreement/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");

          user_info.userName = splited_Data[1];
          user_info.password = splited_Data[2];
          let store_id = splited_Data[3];
          let clinic_agreement_pk = splited_Data[4];
          SessionDetails.SetUserInfo(user_info);
          this.authenticationService.getUserProfile().subscribe((data: any) => {
            if (data.dataList.length > 0) {
              var user_profile = new UserInfo();
              user_profile.email = data.dataList[0].email;
              user_profile.userName = data.dataList[0].userName;
              user_profile.userPk = data.dataList[0].userPk;
              user_profile.userRole = data.dataList[0].userRole;
              user_profile.isAdmin = data.dataList[0].isAdmin;
              user_profile.isPowerUser = data.dataList[0].isPowerUser;
              user_profile.firstName = data.dataList[0].firstName;
              user_profile.lastName = data.dataList[0].lastName;
              user_profile.locationType = data.dataList[0].locationType;
              user_profile.assignedLocations = data.dataList[0].assignedLocations;
              SessionDetails.SetUserProfile(user_profile);
              if(clinic_agreement_pk == null || clinic_agreement_pk == undefined ){
                this.dialogMsg = ErrorMessages['nullClinicAgreementPk'];           
                this.popupShow = true;
                return;
              } 
           
              this.contractApproveService.getContractAgreementApproveData(clinic_agreement_pk).subscribe((data:any) => {
             
                if (data.isApproved == true || data.isApproved == 1) {

                  let contractdateApproved = null;
               

                  if(data.dateApproved){
                    contractdateApproved = new Date(data.dateApproved);
                    this.routePageName= getContractComponentName(contractdateApproved,'userauthentication-viewcontract');
                  }                  

                  SessionDetails.fromUserPage(true);
                  sessionStorage["clinic_agreement_pk"] = clinic_agreement_pk;
                  
                  this.router.navigateByUrl(this.routePageName); 
                }
                else {
               
                  SessionDetails.SetAgreementPK(clinic_agreement_pk);
                  this.router.navigateByUrl("/communityoutreach/contract");
                }

              });
            }

          }, err => {
            if (err.status == 401) {
           
              SessionDetails.SetUserInfo(null);
              console.log(err.statusText);
            }
          });

        }
      });
    }

    else if (encoded_value.includes("/landing/")) {
      let encoded_data = encoded_value.replace('/landing/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {        
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          var user_info = new UserLoginInfo();
          user_info.userName = splited_Data[0].replace('^', '.');
          user_info.password = splited_Data[2];
          SessionDetails.SetUserInfo(user_info);
          this.authenticationService.getUserProfile().subscribe((data: any) => {
            if (data.dataList.length > 0) {
              var user_profile = new UserInfo();
              user_profile.email = data.dataList[0].email;
              user_profile.userName = data.dataList[0].userName;
              user_profile.userPk = data.dataList[0].userPk;
              user_profile.userRole = data.dataList[0].userRole;
              user_profile.isAdmin = data.dataList[0].isAdmin;
              user_profile.isPowerUser = data.dataList[0].isPowerUser;
              user_profile.firstName = data.dataList[0].firstName;
              user_profile.lastName = data.dataList[0].lastName;
              user_profile.locationType = data.dataList[0].locationType;
              user_profile.assignedLocations = data.dataList[0].assignedLocations;
              SessionDetails.SetUserProfile(user_profile);
              if (splited_Data.length == 3) {
                this.router.navigate(['/landing']);
              }
              else if (splited_Data.length >= 5) {                
                if (splited_Data.length > 5 && splited_Data[4] != 'schApptsRepForRemEmail') {
                  var business_data = new EventDetailsData();
                  business_data.clinicPk = business_data.contactLogPk = splited_Data[5];
                  business_data.clinicType = splited_Data[6];
                  let eventDetails = business_data;
                  SessionDetails.EventDetails(eventDetails);
                  if (encoded_value.includes("/landing/") && splited_Data[4] == 'contract') {
                    if (splited_Data[5].length > 0) {
                      SessionDetails.SetAgreementPK(splited_Data[5]);
                    }
                    else {
                      SessionDetails.SetAgreementPK(splited_Data[6]);
                    }
                  }
                  if (splited_Data[5] == "IsPCA") {
                    SessionDetails.setIsPCA(true);
                    SessionDetails.SetAgreementPK(splited_Data[6]);
                    SessionDetails.StoreId(splited_Data[3]);
                  }
                }
                if(splited_Data.length == 7 && splited_Data[4] =='schApptsRepForRemEmail'){
                  let corporate_clinic_info = {};
                  corporate_clinic_info["clinicPk"] = splited_Data[5];
                  //corporate_clinic_info["clientId"] = splited_Data[9];
                  corporate_clinic_info["clinicDate"]= splited_Data[6];
                  //corporate_clinic_info["businessPk"] = splited_Data[5];
                  //corporate_clinic_info["businessName"] = splited_Data[6];
                 // corporate_clinic_info["clinicRoom"] = splited_Data[10];
                  sessionStorage.setItem("isOpenedFromReminderEmail","true");
                  sessionStorage.setItem("event_details",JSON.stringify(corporate_clinic_info));
                }
                if(splited_Data.length == 7 && splited_Data[4] == 'corpStoreApptTracker'){
                  this._previewService.getClinicInfoForTracker(splited_Data[3],splited_Data[6],splited_Data[5]).subscribe((data: any) => {
                    if (Object.keys(data).length > 0) {
                      let dt  = new Date(data.eventDate);
                      data.eventDate = (((dt.getMonth() + 1) < 10) ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "/" + ((dt.getDate() < 10) ? '0' + dt.getDate() : dt.getDate()) + "/" + dt.getFullYear();
                      sessionStorage.setItem("isOpenedFromReminderEmail","true");
                      sessionStorage.setItem("event_details", JSON.stringify(data));
                      this.router.navigateByUrl('/communityoutreach/' + splited_Data[4]);
                    }
                  });
                }
                if ((splited_Data[4] != "schApptsRepForRemEmail" && splited_Data[4] != "corpStoreApptTracker") || splited_Data[4] == "schApptsRepForRemEmail") {
                let agreementPk = SessionDetails.GetAgreementPK();
                if(agreementPk == null || agreementPk == undefined ){
                  this.dialogMsg = ErrorMessages['nullClinicAgreementPk'];           
                  this.popupShow = true;
                  return;
                }                
                if(splited_Data.length == 6 && splited_Data[4] =='home'){ 
                  sessionStorage.setItem("tabIndex",splited_Data[5]);
                }
                
                if(splited_Data.length == 5 && splited_Data[4] =='approveCancellation'){ 
                  this.contractApproveService.approveClinicCancellationByClient(splited_Data[3]).subscribe((data:any)=>{
                    sessionStorage.setItem("CancellationToken","1");
                    if(data&&data.contactName != null && data.contactName != undefined){
                    SessionDetails.setWagsContactInfo(data);
                    }
                    this.router.navigate(['/approveCancellation']);
                  },
                  err=>{
                    this.dialogMsg = "Expectation Failed.";           
                    this.popupShow = true;
                    return;
                  });
                }
                if(splited_Data.length == 5 && splited_Data[4] =='rejectCancellation'){ 
                  
                  this.contractApproveService.rejectClinicCancellationByClient(splited_Data[3]).subscribe(data=>{
                    sessionStorage.setItem("CancellationToken","2");
                    this.router.navigate(['/rejectCancellation']);
                  },
                  err=>{
                    this.dialogMsg = "Expectation Failed.";           
                    this.popupShow = true;
                    return;
                  })
                }          
                if(splited_Data.length == 6 && splited_Data[4] =='storehome' && splited_Data[5]==="fromContactUs"){ 
                  sessionStorage.setItem("fromContactUsStoreId",splited_Data[3]);
                  SessionDetails.StoreId(splited_Data[3]);
                }
                if(splited_Data.length == 7 && splited_Data[6]?.trim() == 'Equity Events (No Contract)'){
                  this.router.navigateByUrl('/communityoutreach/EquityEventDetails')
                  return;
                }
                this.router.navigateByUrl('/communityoutreach/' + splited_Data[4] + '');
              }
              }
              else {
                // this.popupShow = true;
                this.router.navigate(['/login']);
              }
            }
            else {
              this.popupShow = true;
            }
          }, err => {
            if (err.status == 401) {
              SessionDetails.SetUserInfo(null);
              console.log(err.statusText);

            }
            this.router.navigate(['/login']);
          });

        }
      });
    }
    else if (encoded_value.includes("/ss/schedulerLanding/")) {
      let encoded_data = encoded_value.replace('/ss/schedulerLanding/', '');
      let encrypt_data = new EncryptDecrypt();
      encrypt_data.encryptedS = encoded_data;
      encrypt_data.decryptedS = "";
      let data = this.getUserInfo(41);
      let user_info = new UserLoginInfo();
      user_info.pk = data.pk
      user_info.userName = data.userName;
      user_info.password = data.password;
      user_info.role = data.role;
      SessionDetails.SetUserInfo(user_info);
      this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
        let decrypted_data = data.decryptedS;
        if (decrypted_data != null || decrypted_data != undefined) {
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          let splited_Data = decrypted_data.split("|");
          var scheduleuserInfo = new SchedulerUserInfo();
          scheduleuserInfo.userName = splited_Data[0];
          scheduleuserInfo.userPk = splited_Data[1];
          scheduleuserInfo.password = splited_Data[2];
          scheduleuserInfo.designPk = splited_Data[3];
          scheduleuserInfo.appointmentPK = splited_Data[4];
          scheduleuserInfo.redirectPage = splited_Data[5];
          scheduleuserInfo.clinicPK = splited_Data[6];
          SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
          this.router.navigate(['/schedulerLanding']);
        }
      });
    }
    else if (encoded_value.includes("/ss/")) {
      let encoded_data = encoded_value.replace('/ss/', '');
      if (encoded_data.length <= 9) {
        this.authenticationService.getSchedulerSiteLink(encoded_data).subscribe((result: any) => {
          if (result != "") {
            var encrypt_data = new EncryptDecrypt();
            encrypt_data.encryptedS = result;
            encrypt_data.decryptedS = "";
            let data = this.getUserInfo(41);
            var user_info = new UserLoginInfo();
            user_info.pk = data.pk
            user_info.userName = data.userName;
            user_info.password = data.password;
            user_info.role = data.role;
            SessionDetails.SetUserInfo(user_info);
            this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
              let decrypted_data = data.decryptedS;
              if (decrypted_data != null || decrypted_data != undefined) {
                decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
                let splited_Data = decrypted_data.split("|");
                var scheduleuserInfo = new SchedulerUserInfo();
                scheduleuserInfo.userName = splited_Data[0];
                scheduleuserInfo.userPk = Number(splited_Data[1]);
                scheduleuserInfo.password = splited_Data[2];
                scheduleuserInfo.designPk = Number(splited_Data[3]);
                scheduleuserInfo.appointmentPK = ( splited_Data.length <= 5 && (!splited_Data[4] || typeof (splited_Data[4]) === 'string') ? -1 : Number(splited_Data[4]));
                scheduleuserInfo.redirectPage = ''// splited_Data[5];
                scheduleuserInfo.clinicPK = splited_Data[6] ? Number(splited_Data[6]) : -1;
                SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
                this.router.navigate(['/schedulerLanding']);
              }
            });
          }
        });
      }
      else {
        let encrypt_data = new EncryptDecrypt();
        encrypt_data.encryptedS = encoded_data;
        encrypt_data.decryptedS = "";
        var user_profile = {};
        user_profile = SessionDetails.GetUserProfile();
        /*if (user_profile == undefined || user_profile == null || Object.keys(user_profile).length == 0) {
          let data = this.getUserInfo(41);
          var user_info = new UserLoginInfo();
          user_info.pk = data.pk
          user_info.userName = data.userName;
          user_info.password = data.password;
          user_info.role = data.role;
          SessionDetails.SetUserInfo(user_info);
        }*/
        this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
          let decrypted_data = data.decryptedS;
          decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
          if (decrypted_data != null || decrypted_data != undefined) {
            if (user_profile == undefined || user_profile == null || Object.keys(user_profile).length == 0) {
              //this.createUserProfileForSchedulerLinks();
              this.getAndCreateUserProfileInfo(decrypted_data);
            }
            else {
              this.setEncryptedLinkcScheduerInfo(decrypted_data);
            }
          }
        });
      }
    }
    else if (encoded_value.includes("/contractVoucherVARPdfs/")) {

      let isApp = localStorage.getItem("isApp");
      //console.log(isApp);
      if (isApp) {
        localStorage.removeItem("isApp");
      }
      else {
        this.router.navigate(['/login'])
      }

    }
    else {
      this.router.navigate(['/login'])
    }
  }

  createUserProfileForSchedulerLinks() {
    this.authenticationService.getUserProfile().subscribe((data: any) => {
      if (data.dataList.length > 0) {
        var user_profile = new UserInfo();
        user_profile.email = data.dataList[0].email;
        user_profile.userName = data.dataList[0].userName;
        user_profile.userPk = data.dataList[0].userPk;
        user_profile.userRole = data.dataList[0].userRole;
        user_profile.isAdmin = data.dataList[0].isAdmin;
        user_profile.isPowerUser = data.dataList[0].isPowerUser;
        user_profile.firstName = data.dataList[0].firstName;
        user_profile.lastName = data.dataList[0].lastName;
        user_profile.locationType = data.dataList[0].locationType;
        user_profile.assignedLocations = data.dataList[0].assignedLocations;
        SessionDetails.SetUserProfile(user_profile);
      }
    });
  }

  setEncryptedLinkcScheduerInfo(decrypted_data: any) {
    let splited_Data = decrypted_data.split("|");
    var scheduleuserInfo = new SchedulerUserInfo();
    scheduleuserInfo.userName = splited_Data[0];
    scheduleuserInfo.userPk = splited_Data[1];
    scheduleuserInfo.password = splited_Data[2];
    scheduleuserInfo.designPk = splited_Data[3];
    scheduleuserInfo.appointmentPK = ((!splited_Data[4] || typeof (splited_Data[4]) == 'string') ? -1 : splited_Data[4]);
    scheduleuserInfo.redirectPage = ''// splited_Data[5];
    scheduleuserInfo.clinicPK = splited_Data[6] ? splited_Data[6] : -1;
    SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
    this.router.navigate(['/clientSchedulerApptTracker']);
  }

  getAndCreateUserProfileInfo(decrypted_data: any) {
    let splited_Data: any = decrypted_data.split("|");
    let user_info = new UserLoginInfo();
    user_info.userName = splited_Data[0];
    user_info.password = splited_Data[2];
    SessionDetails.SetUserInfo(user_info);
    this.authenticationService.getUserProfile().subscribe((data: any) => {
      if (data.dataList.length > 0) {
        var user_profile = new UserInfo();
        user_profile.email = data.dataList[0].email;
        user_profile.userName = data.dataList[0].userName;
        user_profile.userPk = data.dataList[0].userPk;
        user_profile.userRole = data.dataList[0].userRole;
        user_profile.isAdmin = data.dataList[0].isAdmin;
        user_profile.isPowerUser = data.dataList[0].isPowerUser;
        user_profile.firstName = data.dataList[0].firstName;
        user_profile.lastName = data.dataList[0].lastName;
        user_profile.locationType = data.dataList[0].locationType;
        user_profile.assignedLocations = data.dataList[0].assignedLocations;
        SessionDetails.SetUserProfile(user_profile);
        sessionStorage.removeItem("unauth");
        let user_data = this.getUserInfo(41);
        var user_info = new UserLoginInfo();
        user_info.pk = user_data.pk
        user_info.userName = user_data.userName;
        user_info.password = user_data.password;
        user_info.role = user_data.role;
        SessionDetails.SetUserInfo(user_info);
        this.setEncryptedLinkcScheduerInfo(decrypted_data);
      }
    }, err => {
      if (err.status == 401) {
        SessionDetails.SetUserInfo(null);
        console.log(err.statusText);

      }
    });
  }

  getUserInfo(pk) {
    let user_Data = JSON.parse(JSON.stringify(userLoginData));//for deep copy
    let login_Info = user_Data.filter(x => x.pk == pk)[0];
    if(login_Info.role !== "Admin"){
    login_Info.userName=SessionDetails.getD(SessionDetails.absJ,login_Info.userName);
    login_Info.password=SessionDetails.getD(SessionDetails.absJ,login_Info.password);
    login_Info.role=SessionDetails.getD(SessionDetails.absJ,login_Info.role);
    }
    return login_Info;
  }
  ok() {
    this.popupShow = false;
  }

  getPCAContractAgreementData(agreement_pk, user_type): any {
    let is_RHD_approved = false;
    this._contractService.getPCAContractAgreement(agreement_pk, user_type).subscribe((data :any)=> {
      is_RHD_approved = data.isRHDApproved;
    });
  }
}
