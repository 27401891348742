// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { BlockedImmunizations } from '../app/config-files/blocked-immunizations';
import { ErrorMessages } from '../app/config-files/error-messages';

export const environment = {
  production: false,
  BlockedImmunizations: [{
    BlockedImmunizations,
    BlockedStartDate: '04/15/2018',
    BlockedEndDate: '07/15/2018'
  }],
  ErrorMessages: ErrorMessages,
  date: '01/12/2018',
  reportStartYear: '2014',
  addCoPayGroupIdAdmins: 'CommunityUpdate@WagOutreach.com,jerry.v.thomas@walgreens.com',
  outreachStartDateIP: '05/01/2018',
  outreachStartDateSO: '06/01/2018',
 
  MO_State_From: '12/01/2022',
  MO_State_TO: '02/01/2023',

  DC_State_From: '5/1/2023',
  DC_State_TO: '2/1/2024',
  
  restrict_states: '[]',
  
  image_file_path: 'assets/images/walgreens_email_logo.png', 
  // API_URL: 'https://tdwagsservicesdev.wagsoutreach.com',
  API_URL: 'https://tdwagsservicesdev.wagsoutreach.com',
  RESOURCES: {
    // CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL: 'https://tdwagsservicesdev.wagsoutreach.com/clientLogos/',
    CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL: 'https://tdwagsservicesdev.wagsoutreach.com'+'/clientLogos/',
    PROOF_OF_DOCUMENTS: 'https://tdwagsoutreachcfiles.blob.core.windows.net/proofofdocs', 
    VERIFY_USER_GET: '/values/GetUserLoginInformation',
    CONFIRM_CLINIC_DETAILS: '/values/confirmClinicDetails',
    GET_NATIONAL_CONTRACT_CLIENTS: '/api/getNationalContractClients',
    GET_STORE_PROFILE: '/api/Store_getStoreProfile',
    GET_ASSIGNED_BUSINESSES: '/api/Store_getAssignedBusinesses',
    GET_OUTREACH_STATUS_LABELS: '/api/ContactLog_getOutreachStatusLabels',
    GET_OUTREACH_CONTACT_LOGS: '/api/ContactLog_getOpportunityContactLogs',
    GET_OPPURTUNITY_DETAILS: '/api/Opportunities_getOpportunityDetails',
    GET_SCHEDULE_EVENT: '/api/ContactLog_getScheduleEvent',
    ADD_OPPORTUNTY: '/api/Opportunities_addOutreachOpportunity',
    UPDATE_OPPORTUNITY: '/api/Opportunities_updateOutreachOpportunity',
    DELETE_NATIONAL_CONTRACT_CLIENTS: '/api/deleteNationalContractClients',
    POST_FOLLOWUP_EVENT: '/api/ContactLog_postFollowUpEvent',
    POST_SCHEDULE_EVENT: '/api/ContactLog_postSrScheduleEvent',
    POST_SIMPLE_EVENT: '/api/ContactLog_postSimpleEvent',
    UPDATE_SCHEDULE_EVENT: '/api/ContactLog_updateScheduleEvent',
    GET_FOLLOWUP_EVENT: '/api/ContactLog_getFollowUpEvent',
    UPDATE_FOLLOWUP_EVENT: '/api/ContactLog_updateFollowUpEvent',
    DELETE_CONTACT_LOG: '/api/ContactLog_deleteContactLog',
    GET_CONTRACT_AGREEMENT: '/api/ContractAgreement_getContractAgreement',
    GET_WAGSOUTREACH_COUNTS: '/api/Main_getWagsOutreachCounts',
    GET_IMMUNIZATION_LIST: '/api/ContractAgreement_getImmunizationList',
    UPDATE_CONTRACT_AGREEMENT: '/api/ContractAgreement_updateContractAgreement',
    POTENTIAL_CLIENT_INTEREST_NEED_MORE_INFO: '/api/DailyEmail_PotentialClientInterest_NeedMoreInfo',
    STORE_CLICKED_YES_FOR_CONTACTUS: '/api/Leads_StoreClickedYesForContactUs',
    STORE_CLICKED_NO_CONTACTUS: '/api/Leads_StoreClickedNoForContactUs',
    UPDATE_CO_AGREEMENT: '/api/ContractAgreement_insertCommOutreachProgram',
    UPDATE_EquityEvent_AGREEMENT : '/api/ContractAgreement_insertEquityEventsProgram',
    AMEND_EquityEvents_AGREEMENT: '/api/ContractAgreement_amendEquityEventsProgram',
    AMEND_CO_AGREEMENT: '/api/ContractAgreement_amendCommOutreachProgram',
    GET_PREVIOUSSEASON_CLINIC_LOCATIONS: '/api/ContractAgreement_getPreviousSeasonClinicLocations',
    POST_ENCRYPTION_DECRYPTION: '/api/Encryption_decrypt',
    GET_USER_PROFILE: '/api/Main_getUserProfile',
    INSERT_CHARITY_PROGRAM: '/api/ContractAgreement_insertCharityVoucherProgram',
    GET_LOCAL_CLINIC_DETAILS: '/api/ClinicDetails_getClinicDetails',
    CACHE_CP_TOKEN_DATA:'/api/CP_cacheCPTokenData',
    UPDATE_CLINIC_DETAILS: '/api/ClinicDetails_updateClinicDetails',
    DELETE_CLINIC_DETAILS: '/api/ClinicDetails_deleteAgreement',
    DELETE_AMEND_AGREEMENT: '/api/DeleteAmendeAgreement',
    AREA_REGION_LIST: '/api/Main_getDistrictAreaRegionList',
    GET_ACTION_ITEM_COUNTS: '/api/Dashboard_getActionItemCounts',
    GET_METRICS_COUNTS: '/api/Dashboard_getMetricCounts',
    GET_DASHBOARD_DATA: '/api/Dashboard_getScheduledClinics',
    GET_CLOSEST_STORE: '/api/Dashboard_getClosestStore',
    UPDATE_CLINIC_ASSIGNMENT: '/api/Dashboard_updateClinicAssignment',
    DUPLICATE_BUSINESS: '/api/Opportunities_checkForDuplicateAddresses',
    GET_CONTRACT_VOUCHER_FORM_DATA: '/api/ClinicDetails_getContractVoucherFormData',
    GET_LOCAL_LEADS: '/api/Dashboard_getLocalLeads',
    UPDATE_LOCAL_LEADS: '/api/Dashboard_updateLocalLeadsAssignment',
    SEND_USER_ACCESS_LINK: '/api/DailyEmail_sendUserAccessLink',
    CLINIC_UPLOAD: '/api/ClinicUpload_upload',
    GET_SCHEDULER_SITE_LINK: '/api/SchedulerCorporate_getSchedulerSiteLink',
    GET_CORPORATE_SCHEDULER_CLINICS: '/api/SchedulerCorporate_getCorporateSchedulerClinics',
    GET_CORPORATE_CLIENTS: '/api/SchedulerCorporate_getCorporateClients',
    GET_SCHEDULER_SITE_DESIGN: '/api/SchedulerSiteDeisgn_getSchedulerSiteDesign',
    GET_APPOINTMENT_TRACKING_DETAILS: '/api/SchedulerEeApptDetails_getEeApptDetails',
    UPDATE_SCHEDULER_CLIENT_SETUP: '/api/SchedulerUpdateClientSetup_postClientSetup',
    CLINIC_UPLOAD_IMAGE: '/api/LogoUpload_upload',
    GET_USER_ROLES: '/api/get_userRoles',
    GET_RHD_DETAILS: '/api/Store_getStoreRhdDetails',
    GET_TPOPPORTUNITY_DETAILS: '/api/Pca_getOpportunityDetails',
    TESTING_PARTNER_NAMES: '/api/PCA_getTestingPartnerNames',
    ADD_PCA_OPPORTUNTY: '/api/PCA_addPCAOutreachOpportunity',
    UPDATE_PCA_OPPRTUNITY: '/api/PCA_updatePCAOutreachOpportunity',
    GET_PCA_OpportunityDetails: '/api/PCA_getOpportunityDetails',
    GET_OPPORTUNITY_DETAILS: '/api/Opportunities_getOpportunityDetails',
    GET_PCA_CONTRACTAGREEMENT: '/api/PCA_getContractAgreement',
    UPLOAD_PROOF_OF_INSURANCE: '/api/PCA_uploadProofOfInsurance',
    GET_PROOF_OF_INSURANCE: '/api/PCA_GetProofOfInsurance',
    DELETE_PROOF_OF_INSURANCE: '/api/PCA_DeleteProofOfInsurance',
    UPDATE_CONTRACT_AGREEMNET_TESTING_PARTNER: '/api/ContractAgreement_updatePCAContractAgreementForTestingPartner',
    APPROVE_PCA_CONTRACT_AGREEMENT: '/api/ContractAgreement_approvePCAContractAgreementForTestingPartner',
    VALIDATE_RHD_RVP: '/api/PCA_ValidateRHDEmail',
    VALIDATE_PORTAL_EMAIL: '/api/ContractAgreement_validatePortalEmail',
    GET_PCA_DETAILS: '/api/ClinicDetails_getTestingEventDetails',
    UPDATE_PCA_DETAILS: '/api/ClinicDetails_updateTestingEventDetails',
    GET_ORGANIZATION_TYPES: '/api/getOrganizationsList_get',
    GET_PCA_DASHBOARD_SNAPSHOT: '/api/Dashboard_getPCADashboardSnapshot',
    CONFIRM_CANCELLED_APPOINTMENTS: '/api/updateCanceledClinics',
    GET_CANCEREVENTS_COUNT: '/api/getCancerEventsCount',
    GET_IMMUNIZATION_CHECKS: '/api/ImmunizationCheck_getImmunizationChecks',
    GET_IMMUNIZATION_CHECKBYPK: '/api/ImmunizationCheck_getImmunizationCheckByPk',
    UPDATE_IMMUNIZATION_CHECK: '/api/ImmunizationCheck_updateImmunizationCheck',
    CREATE_IMMUNIZATION_CHECK: '/api/ImmunizationCheck_createImmunizationCheck',
    GET_ALL_CLINIC_TYPES: '/api/ImmunizationCheck_getAllClinicTypes',
    GET_STOREPROFILE_UPLOADED_FILES: '/api/StoreUpload_getUploadedFilesList',
    UPLOAD_STORE_PROFILES: '/api/StoreUpload_upload',
    GET_GENERATED_STOREPROFILE_REPORT: '/api/StoreUpload_getGeneratedStoreProfileReport',
    UPDATE_STOREUPLOAD_PROFILE: '/api/StoreUpload_updateStoreProfile',
    DELETE_STOREUPLOAD_PROFILE: '/api/StoreUpload_deleteStoreProfile',
    DELETE_STOREUPLOAD_FILE: '/api/StoreUpload_deleteFile',
    GET_STOREUPLOAD_FILE_COUNTS: '/api/StoreUpload_getFileCounts',
    DOWNLOAD_STOREPROFILE_REPORT: '/api/StoreUpload_downloadReport',
    UPDATE_STOREPROFILES_INSITE: '/api/StoreUpload_updateStoreProfiles',
    DROPPED_STORES_FOR_REASSIGNMENT: '/api/getDroppedStoresForReassignment',
    GET_SOURCE_PATIENTGROUPS: '/api/searchCuraPatientPatientGroups',
    GET_TARGET_PATIENTGROUPS: '/api/searchCuraPatientPatientGroups',
    MOVE_PATIENTS_BETWEEN_PATIENTGROUPS: '/api/movePatientsBetweenPatientGroups',
    REASSIGN_DROPPEDSTORES_TO_CLOSESTSTORE: '/api/reassignDroppedStoresToClosestStore',
    GROUPID_UPLOAD: '/api/GroupIdUpload_upload',
    GET_UNASSIGNED_GROUPID_COUNT: '/api/GetUnsssignedGroupIdsCount',
    GET_STATS_DATA: '/api/JsStatsReporting/getStats',
    GET_IP_BUSINESS_FEEDBACK_ALL: '/api/JsStatsReporting/getStoreIPBusinessFeedbackAll',
    GET_OPPORTUNITIES_BY_SIC: '/api/JsStatsReporting/getOpportunityCountsBySIC',
    GET_ASSIGNED_30PLUS_OPPORTUNITIES: '/api/JsStatsReporting/getAssigned30PlusOpportunities',
    GET_STORE_COUNT_BY_ASSIGNED_OPPORTUNITIES: '/api/JsStatsReporting/getStoreCountByAssignedOpportunities',
    GET_STORE_COUNT_BY_ASSIGNED_RANGE: '/api/JsStatsReporting/getStoreCountByAssignedRange',
    GET_IP_CLINICS_BY_SIC: '/api/JsStatsReporting/getIPClinicsBySIC',
    GET_SR_EVENTS_BY_SIC: '/api/JsStatsReporting/getSREventsBySIC',
    REMOVE_CLINIC_CANCELLED_STATUS: "/api/ClinicDetails_removeCancelledClinicDetails",
    REJECT_CLINIC_CANCELLATION_BY_CLIENT: "/api/ClinicDetails_rejectClinicCancellationByClient",
    APPROVE_CLINIC_CANCELLATION_BY_CLIENT: "/api/ClinicDetails_approveClinicCancellationByClient",
    GET_CONFIG_DATES: "/api/getConfigDates",
    SAVE_LEAD_ENQUIRIES:"/api/Leads_saveLeads",
    GET_LEADS_VACCINATION_INFO: "/api/getLeadvaccinationinfo",
    GET_CONTACTUS_REPORT: "/api/JsContctUs_get",
    VERIFY_RECAPTCHA: "/api/verifyCaptcha",
    GET_CLINIC_INFO_FOR_TRACKER: "api/getClinicInfoForTracker",
    GET_LOOKUP_VALUES: "api/Main_getLookUpValues",
    UPDATE_OUTREACH_STATUS_TO_NOOUTREACH: "/api/Opportunities_updateOutreachStatusToNoOutreach",
    DELETE_OPPORTUNITY_INFO: "/api/Opportunities_deleteOpportunityInfo",
    LEAD_AWAITING_RESPONSE_OVER_THREE_DAYS: "/api/GetLeadsAwaitingResponseOverThreeDays",
    LEAD_AWAITING_RESPONSE_BY_ID: "/api/GetLeadAwaitingResponseById",
    REASSIGN_CLOSEST_STOREBY_ID : "/api/ReassignClosestStoreById",
    GET_PRIORITY_OPPORTUNITIES: "/api/GetPriorityOpportunities",
    GET_OPPORTUNITIES_REQUIRING_ACTION: "api/getipopportunitiesrequiringaction",
    GET_PRIORITY_OPPORTUNITIES_REQUIRING_ACTION: "api/getipopportunitiespriorityrequiringaction",
    GET_OPPORTUNITIES_REQUIRING_ACTION_REPORTS: "api/getipopportunitiesreportaction",
    GET_PRIORITY_OPPORTUNITIES_REQUIRING_ACTION_REPORTS: "api/getipopportunitiespriorityreportaction",
    GET_CLINIC_SCHEDULAR_APPOINTMENTS: "/api/GetScheduleClinicImmunizationData",
    DOWNLOAD_OPPOURTUNITY_REPORT: "/api/downloadOpportunitiesRequiringReport",
    GET_OPPOURTUNITY_REPORT_OUTREACH_AND_FOLLOW_UP_COUNT: "/api/getopportunitiescounts",
    DOWNLOAD_PRIORITY_OPPOURTUNITY_REPORT: "api/downloadPriorityReport",
    RESOURCE_COUNT : "/api/SaveResourceCount",
    GET_RESOURCE_COUNT: "api/GetResourceCount"
  },
  // Reports - Constants
  ipSeason: 2021,
  seasonStartDate: '05/01/2014',
  seasonEndDate: '04/30/2018',
  outreachEffort: 1,
  outreachStartDate: '05/03/2024',
  SuperAdmins: [
    "admin_communityupdate", 
    "admin_samuel.nass", 
    "admin_jerry.v.thomas", 
    "admin_bryan.shrode",
    "admin_enterpriseimmunizationservice",
    "admin_carlos.flores",
    "admin_tyrone.clark",
    "admin_roxanne.crowley",
    "admin_breeann.applegate"
  ],
  covidVaccineNotAviableDates: {
    startDate: "20/05/2024",
    endDate: "15/08/2024"
  },
  // Below Variable for only site maintenance
  isSiteDeploying: false,

  //Service Fee calculations
  NO_SERVICE_FEE: 0,
  SERVICE_FEE_1: 750,
  SERVICE_FEE_2: 1000,
  SERVICE_FEE_3: 2000,


  //Service Fee
  serviceFeeAgreement :{
   servicefeeMessage  : "I acknowledge that I have informed the client of the service fee associated with each clinic which is based on the estimated volume provided by the client.",
   servicefeeupdateMessage: "I acknowledge that I have informed the client of the service fee associated with each clinic which is based on the estimated number of participants provided by the client.",
   agreementDate: " 5/1/2024",
  }
};